import { Main } from "components/pages/Main";
import {BrowserRouter, Route, Routes } from "react-router-dom";
import { Download } from "components/pages/Download";


export function App() {
  return <BrowserRouter>
    <Routes>
      <Route path="/:UserId?" element={<Main/>}/>
      <Route path="/download/:FileId" element={<Download/>}/>
    </Routes>
  </BrowserRouter>
}
