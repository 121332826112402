import {isEqual, remove} from 'lodash'

const ongoingRequests = [];

/**@returns{Promise} */
export function singleFetch(url, /**@type{RequestInit}*/requestOptions) {
    var existingRequest = ongoingRequests.find((request) => url === request.url && isEqual(requestOptions, request.options));
    if (existingRequest === undefined) {
        let promise = fetch(url, requestOptions)
        .then(response => {
            return response.json();
        });
        promise.catch((e)=>{
            console.log(e);
        }).finally(()=> {
            remove(ongoingRequests, (ongoingRequest) => {
                return ongoingRequest.promise === promise;
            });
        });
        existingRequest = {url, options : requestOptions, promise};
        ongoingRequests.push(existingRequest);
    }
    return existingRequest.promise;
}
