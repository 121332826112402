import { getApiPath } from "common/functions";
import { singleFetch } from "common/singleFetch";
import { useEffect, useRef, useState } from "react";

export function FileUploadControl({userId, onUploadFinished}) {   
    function startUpload() {
        setIsUploading(true);
        setUploadProgress("Initiating Upload");
        uploadCanceler.current = new AbortController();

        let uploadIdInProgress = null;

        const url = `${getApiPath()}createUpload/${userId}`;
        const uploadIdPromise = singleFetch(url, {method:"POST", signal:uploadCanceler.current.signal});

        uploadIdPromise.then((response) => {
            uploadIdInProgress = response.uploadId;
            const url = `${getApiPath()}Upload/${uploadIdInProgress}?fileName=${newFileName}`;
            return singleFetch(url, {method:"POST", body:fileToUpload, signal:uploadCanceler.current.signal});
        }).then((response) => {
            if (response.fileId) {
                setUploadProgress(null);
                onUploadFinished();
            } else if (response.insuficientStorage) {
                setUploadProgress("Not enough storage on the server");
            } else {
                setUploadProgress("Something went wrong");
            }
        }).catch((e) => {
            if (e === "Cancell button") {
                setUploadProgress(null);
            } else if (e === "Insuficient Storage") {
                setUploadProgress("Not enough storage on the server");
            } else if (e === "Connection Interrupted") {
                setUploadProgress("Upload connection was interupted, please try again");
            } else {
                setUploadProgress("Unexpected error. Try refreshing the page");
            }
        }).finally(() => {
            uploadIdInProgress = null;
            setIsUploading(false);
        });

        uploadIdPromise.then(async (response) => {
            const monitoredUploadId = response.uploadId;
            const url = `${getApiPath()}UploadProgress/${monitoredUploadId}`;
            do {
                const response = await singleFetch(url);

                if (response.status === 2) {
                    uploadCanceler.current.abort("Insuficient Storage");
                }

                if (response.status === 3) {
                    uploadCanceler.current.abort("Connection Interrupted");
                }

                if (response.uploaded !== undefined && response.total !== 0) {
                    const uploadProgress = response.uploaded / response.total;
                    setUploadProgress(uploadProgress);
                }

                await new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 5_000);
                });
            } while (monitoredUploadId === uploadIdInProgress);
        });
    }

    const uploadCanceler = useRef(new AbortController());
    const[isUploading, setIsUploading] = useState(false);
    const[fileToUpload, setFileToUpload] = useState(/**@type {File}*/ (null));
    const[newFileName, setNewFileName] = useState("");
    const[uploadProgress, setUploadProgress] = useState(/**@type {string}*/ (null));

    useEffect(()=>{
        if (fileToUpload == null) {
            setNewFileName("");
        } else {
            setNewFileName(fileToUpload.name);
        }
    }, [fileToUpload]);

    return <div>
        <p>UploadFile:</p>
        
        <input type="file" style={{display:"block"}} onChange = {(e) => setFileToUpload(e.target.files[0])}/>

        <span>
            <input type="text" placeholder="New File Name" disabled={fileToUpload == null || isUploading} value={newFileName} onChange={(e)=>{setNewFileName(e.target.value)}}/>
            {!isUploading && <button disabled={fileToUpload == null || newFileName === ""} onClick={startUpload}>Upload</button>}
            {isUploading && <button onClick={()=>uploadCanceler.current.abort("Cancell button")}>Cancel</button>}
        </span>      
        
       
        {uploadProgress && <p>{uploadProgress}</p>}
    </div>;
}

