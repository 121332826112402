export function formatDataSize(/**@type{number}*/sizeInBytes) {
    const units = ["b", "Kb", "Mb", "Gb", "Tb"];
    let i = 0;
    let size = sizeInBytes;
    while(size > 1024 && i < units.length) {
        i++;
        size = size / 1024;
    }
    return size.toFixed(2) + " " + units[i];
}

let apiPath = null;
export function getApiPath() {
    if (apiPath !== null) {
        return apiPath;
    }

    apiPath = process.env.REACT_APP_API_PATH;
    const originNotation = "ooo|";
    const prefix = apiPath.substring(0, originNotation.length);
    if (prefix === originNotation) {
        const path = apiPath.substring(originNotation.length, apiPath.length);
        apiPath = window.location.origin + path;
    } 

    return getApiPath();
}