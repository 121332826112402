import {useState, useEffect } from 'react';
import {singleFetch} from 'common/singleFetch'
import { getApiPath } from 'common/functions';

const USER_ID = "userId";

export function UserControl({userId, setUserId}) {
    function getNewId() {
      localStorage.removeItem(USER_ID);
      window.location.pathname = '/'
    }
    
    function useNewId() {
      window.location.pathname = `/${newUserId}`
    }
    
    const [newUserId, setNewUserId] = useState('');
    const [showGetNewId, setShowGetNewId] = useState(false);
    const [userIdStatusText, setUserIdStatusText] = useState('');
  
    useEffect(() => {
      if (userId == null) {
        let pathUserId = window.location.pathname.split('/')[1];
        let storedId = localStorage.getItem(USER_ID);
        if (pathUserId !== '') {
          //Confirming current user Id
          let url = `${getApiPath()}getUser/${pathUserId}`;
          singleFetch(url)
            .then((jsonResponse) => {
              if (jsonResponse.userIdValid === true) {
                localStorage.setItem(USER_ID, jsonResponse.sId);
                setUserId(jsonResponse.sId);
                setUserIdStatusText(`Your current id is: ${jsonResponse.sId}`);
              } else {
                if (pathUserId === storedId) {
                  setUserIdStatusText(`Your current stored id ${storedId} is INVALID. It could of being deleted due to inactivity.`);
                  setShowGetNewId(true);
                } else {
                  setUserIdStatusText(`The user id ${pathUserId} is invalid.`);
                }
              }
            })
            .catch((error) => {
              console.log(`Cought error (UserControl): ${error}`);
              setUserIdStatusText('Could not validate user id, try refreshing the page');
            });
        } else if (storedId !== null) {
          window.location.pathname = '/'+storedId;
        } else {
          //Creating new user
          let url = `${getApiPath()}createUser`;
          singleFetch(url, {method : "POST"})
            .then((jsonResponse) => {
              window.location.pathname = '/'+jsonResponse.sId;
            })
            .catch((error) => {
              console.log(`Cought error (UserControl/2): ${error}`);
              setUserIdStatusText('Could not obtain a new user id, try refreshing the page');
            });
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, showGetNewId, userIdStatusText]);
       
    return (
    <div>
        <p>{userIdStatusText}</p>
        <p>Use a different User Id &nbsp; 
            <input name={USER_ID} size={10} onChange={(e) => {setNewUserId(e.target.value)}}/> 
            <button disabled={newUserId === ''} onClick={useNewId}>Go</button>
        </p>
        
        {showGetNewId && <button onClick={getNewId}>Get New Id</button>}
    </div>
    );
}