const { getApiPath, formatDataSize } = require("common/functions");
const { singleFetch } = require("common/singleFetch");
const { useState, useEffect } = require("react")

export function AvailableServerStorage({refreshRef}) {
    refreshRef.refresh = () => {
        setStorageSize(null);
    }

    const [storageSize, setStorageSize] = useState(/**@type{string}*/(null));

    useEffect(() => {
        if (storageSize !== null) {
            return;
        }

        const url = `${getApiPath()}totalSpace`;
        singleFetch(url).then((result) => {
            if (result.totalAvailableUserFileSpace) {
                const readableStorageSpace = formatDataSize(result.totalAvailableUserFileSpace);
                setStorageSize(readableStorageSpace);
            }
        }, [storageSize]);
        
    }, [storageSize]);

    return <div>
        {storageSize && <h5>{storageSize} are available for all users.</h5>}
    </div>
}