import { getApiPath } from "common/functions";
import { FileData } from "common/models";
import { singleFetch } from "common/singleFetch";
import { useEffect, useState } from "react";

/**
 * @param {Object} props
 * @param {string} props.userId
 * @param {FileData} props.fileData
 * @param {Function} props.onFileChanged
 * @param {Function} props.onFileDeleted
 */
export function FileItem({userId, fileData, onFileChanged, onFileDeleted}) {
    function deleteFile() {
        const url = `${getApiPath()}deleteFile/${userId}/${fileData.id}`;
        singleFetch(url, {method:"POST"})
            .then((response) => {
                if (response.fileDeleted) {
                    onFileDeleted();
                }
            });
    }

    function updateFileData() {
        const url = `${getApiPath()}changeFileData/${userId}/${fileData.id}`;

        const updateData = new FileUpdateData();
        updateData.FileName = newFileName;
        updateData.DeleteDownloads = newDeleteDownloads;
        updateData.DeleteAt = getDeleteAt();
        updateData.adjustData(fileData);
        const requestBody = JSON.stringify(updateData);

        singleFetch(url, {method:"POST", body:requestBody, headers:{"Content-Type": "application/json"}})
            .then((response) => {
                if (response.fileUpdated) {
                    onFileChanged();
                }
            });
    }

    function isValidForUpdate() {
        const currentTimeUnit = getTimeUnit(fileData.deleteAt);
        const currentDeleteTimeValue = getTimeValue(fileData.deleteAt, currentTimeUnit);

        return fileData.fileName !== newFileName || fileData.deleteDownloads !== newDeleteDownloads 
                || currentTimeUnit !== deleteAfterTimeUnit || currentDeleteTimeValue !== deleteAfterValue;
    }

    function getTimeUnit(deleteAt) {
        if (deleteAt === 0) {
            return "disabled"
        }

        const currentTime = Date.now() / 1000;
        const deleteAfterSeconds = deleteAt - currentTime;
        if (deleteAfterSeconds < 2 * 60 * 60) {
            return "minutes";
        } else if (deleteAfterSeconds < 72 * 60 * 60) {
            return "hours";
        } else {
            return "days";
        }
    }

    function getTimeValue(deleteAt, timeUnit) {
        if (timeUnit === "disabled") {
            return 0;
        }

        const currentTime = Date.now() / 1000;
        const deleteAfterSeconds = deleteAt - currentTime;
        switch(timeUnit) {
            case "days" : return Math.floor(deleteAfterSeconds / (24 * 60 * 60)) + 1;
            case "hours": return Math.floor(deleteAfterSeconds / (60 * 60)) + 1;
            default : return Math.floor(deleteAfterSeconds / 60) + 1;
        }
    }

    function getDeleteAt() {
        if (deleteAfterTimeUnit === "disabled") {
            return 0;
        }

        let deleteAfter;
        switch(deleteAfterTimeUnit) {
            case "days" : deleteAfter = deleteAfterValue * 24 * 60 * 60; 
            break;
            case "hours": deleteAfter = deleteAfterValue * 60 * 60; 
            break;
            default : deleteAfter = deleteAfterValue * 60;
        }
        return Math.floor(Date.now() / 1000) + deleteAfter;
    }

    const[newFileName, setNewFileName] = useState(fileData.fileName);
    const[newDeleteDownloads, setDeleteDownloads] = useState(fileData.deleteDownloads);
    const[deleteAfterValue, setDeleteAfterValue] = useState(0);
    const[deleteAfterTimeUnit, setDeleteAfterTimeUnit] = useState("disabled");

    useEffect(() => {
        const initialTimeUnit = getTimeUnit(fileData.deleteAt);
        const initialTimeValue = getTimeValue(fileData.deleteAt, initialTimeUnit);
        setDeleteAfterValue(initialTimeValue);
        setDeleteAfterTimeUnit(initialTimeUnit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (deleteAfterTimeUnit === "disabled") {
            if (deleteAfterValue !== 0) {
                setDeleteAfterValue(0);
            }
            return;
        }

        if (deleteAfterValue > 120) {
            setDeleteAfterValue(120);
        } else if (deleteAfterValue < 1) {
            setDeleteAfterValue(1);
        }
    }, [deleteAfterValue, deleteAfterTimeUnit]);

    return <div style={{display:"flex", flexDirection:"row"}}>
        <div style={{width:"2em", flex:1}}><input style={{width:"100%"}} type="text" value={newFileName} onChange={(e) => {setNewFileName(e.target.value)}}/></div>
        <div style={{width:"7em"}}><input style={{width:"6.5em"}} type="number" min="0" value={newDeleteDownloads} onChange={(e) => {setDeleteDownloads(+e.target.value)}}/></div>
        <div style={{width:"8em"}}>
            <input style={{width:"3em", display:"inline"}} type="number" min="1" max="120" disabled={deleteAfterTimeUnit === "disabled"}
                value={deleteAfterValue} onChange={(e) => setDeleteAfterValue(+e.target.value)}/>
            <select style={{display:"inline"}} value={deleteAfterTimeUnit} onChange={(e) => {setDeleteAfterTimeUnit(e.target.value)}}>
                <option>disabled</option>
                <option>minutes</option>
                <option>hours</option>
                <option>days</option>
            </select>
        </div>
        <div style={{width:"7em"}}>{fileData.downloads}</div>
        <div style={{width:"10em"}}>{fileData.getFormatedLastActive()}</div>
        <div style={{width:"7em"}}>{fileData.getFormatedSize()}</div>
        <div style={{flex:2}}><a href={fileData.getDownloadUrl()}>{fileData.getDownloadUrl()}</a></div>
        <div style={{width:"8em"}}>
            <button disabled={!isValidForUpdate()} onClick={updateFileData}>Update</button>
            <button style={{marginLeft:"5pt"}} onClick={deleteFile}>Delete</button>
        </div>
    </div>;
}

class FileUpdateData {
    FileName;
    DeleteAt;  
    DeleteDownloads;

    /**@param{FileData} fileData */
    adjustData(fileData) {
        if (this.DeleteDownloads !== 0) {
            if (this.DeleteDownloads <= fileData.downloads) {
                this.DeleteDownloads = fileData.downloads + 1
            }
        }
    }
}