import { formatDataSize, getApiPath } from "common/functions";
import { singleFetch } from "common/singleFetch";
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";

export function Download() {

    const[fileData, setFileData] = useState(/**@type{DownloadFileData}*/(null));
    const fileId = useLocation().pathname.split("/")[2];

    useEffect(() => {
        if (fileData != null) {
            return;
        }
        const url = `${getApiPath()}fileData/${fileId}`;
        singleFetch(url).then((response)=> {
            setFileData(new DownloadFileData(response));
        }).catch((e) => {
            console.log(e);
            setFileData(new DownloadFileData({}));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileData]); 

    let resultView = null;
    if (fileData != null) {
        if (fileData.otherError) {
            resultView = <SomeError/>;
        } else if (fileData.badId) {
            resultView = <InvalidFileId fileId={fileId}/>;
        } else if (fileData.notFound) {
            resultView = <FileNotFound fileId={fileId}/>;
        } else {
            resultView = <DownloadFile fileId ={fileId} downloadFileData={fileData}/>;
        }
    }

    return <div>
        {resultView}
    </div>
}

function SomeError() {
    return <div>
        <h1>Something Went Wrong!</h1>
        <h2>Unable to retrieve file data</h2>
        <h3>Try again later</h3>
    </div>
}

function InvalidFileId({fileId}) {
    return <div>
        <h1>The file you are trying to access has a malformed Id: {fileId}</h1>
        <h3>Please double check the source (URL) of the file</h3>
    </div>
}

function FileNotFound({fileId}) {
    return <div>
        <h1>The file with id {fileId}, not found.</h1>
        <h3>It could of been deleted or it never existed in the first place.</h3>
        <h3>Please double check the source (URL) of the file.</h3>
    </div>
}

/**
 * @param {Object} param
 * @param {DownloadFileData} param.downloadFileData
 */
function DownloadFile({downloadFileData, fileId}) {
    const url = `${getApiPath()}download/${fileId}`;
    const size = formatDataSize(downloadFileData.size);
    return <div>
        <h3>You are about to download</h3>
        <h4>{downloadFileData.fileName}</h4>
        <h5>{size}</h5>
        <a href={url}>Download</a>
    </div>
}

class DownloadFileData {
    badId = false;
    notFound = false;
    otherError = false;
    fileName = null;
    size = null;

    constructor(o) {
        if (o.fileName) {
            this.fileName = o.fileName;
            this.size = o.size;
        } else if (o.notFound) {
            this.notFound = true;
        } else if (o.badId) {
            this.badId = true;
        } else {
            this.otherError = true;
        }
    }
}