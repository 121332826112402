import { singleFetch } from "common/singleFetch";
import { useEffect, useState } from "react"
import { FileData } from "common/models";
import { FileItem } from "components/FileItem";
import { getApiPath } from "common/functions";

export function FileList({userId, refreshRef, onFileDeleted}) {

    function getFiles() {
        const url = `${getApiPath()}getFiles/${userId}`;
        singleFetch(url).then((response) => {
            const files = response.map((fileObject) => {return new FileData(fileObject)});
            setFiles(files);
        });
    }

    function refresh() {
        setFiles(null);
    }

    function onFileDeleted2() {
        refresh();
        onFileDeleted();
    }

    const [files, setFiles] = useState(/**@type{Array<FileData>}*/(null));
    refreshRef.refresh = refresh;
    
    useEffect(() => {
        if (files === null) {
            getFiles();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const fileList = [];
    if (files != null) {
        files.forEach((file) =>{
            const fileItem = <FileItem userId={userId} fileData={file} onFileChanged={refresh} onFileDeleted={onFileDeleted2} key={file.id}/>;
            fileList.push(fileItem);
        });
    }

    return <div>
        <div style={{display:"flex", flexFlow:"row", alignItems:"end"}}>
            <div style={{flex:10}}>File Name</div>
            <div style={{width:"7em", whiteSpace:"pre"}}>Delete After<br/>      (x)<br/>Downloads</div>
            <div style={{width:"8em", whiteSpace:"pre"}}>Delete After</div>
            <div style={{width:"7em"}}>Total<br/>Downloads</div>
            <div style={{width:"10em"}}>Last Activity</div>
            <div style={{width:"7em"}}>File Size</div>
            <div style={{flex:20}}>Download Url</div>
            <div style={{width:"8em"}}/>
        </div>
        <div>
            {fileList}
        </div>
    </div>
}