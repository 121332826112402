import { UserControl } from "components/UserControl";
import { useState } from "react";
import { FileUploadControl } from "components/FileUploadControl";
import { FileList } from "components/FileList";
import { AvailableServerStorage } from "components/AvailableServerStorage";

export function Main() {
  function onUploadFinished() {
    fileListRefreshRef.refresh();
    availableStorageRefreshRef.refresh();
  }

  function onFileDeleted() {
    availableStorageRefreshRef.refresh();
  }
  
  const fileListRefreshRef = {refresh : null};
  const availableStorageRefreshRef = {refresh : null};
  const [userId, setUserId] = useState(null);
  return <div>
    <UserControl userId={userId} setUserId = {setUserId}/>
    {userId && <FileUploadControl userId = {userId} onUploadFinished={onUploadFinished}/>}
    {userId && <AvailableServerStorage refreshRef={availableStorageRefreshRef}/>}
    {userId && <FileList userId={userId} refreshRef={fileListRefreshRef} onFileDeleted={onFileDeleted}/>}
  </div>
}
